/*! Agile Pixel https://agilepixel.io - 2022*/
const applyFieldActive = function(field, input) {
    field.classList.toggle('active', input.value !== '');
};

const initInputs = function(inputs, form, field = false, isGravityForm = false) {
    for (const input of inputs) {
        const fieldtobeActive = field === false ? input.parentNode : field;
        const classNode = isGravityForm ? input.parentNode.parentNode.parentNode.parentNode : input.parentNode;
        switch (input.type) {
        case 'radio':
        case 'checkbox':
            
            if (input.disabled) {
                classNode.classList.add('disabled');
            }
            if (input.checked){
                classNode.classList.add('active');
            }
            
            input.addEventListener('change', () => {
                const { checked, name, value } = input;
                if (checked) {
                    classNode.classList.add('active');
                    if (input.type == 'radio') {
                        const deactivate = form.querySelectorAll(`input[name="${name}"]:not([value="${value}"])`);
                        for (const d of deactivate) {
                            d.parentNode.classList.remove('active');
                        }
                    }
                } else {
                    classNode.classList.remove('active');
                }
            });
            break;
        default:
            if ((input.type == 'text' || input.type == 'email') && input.value != ''){
                applyFieldActive(fieldtobeActive, input);
            }            
            input.addEventListener('change', () => {
                applyFieldActive(fieldtobeActive, input);
            });
            input.addEventListener('focus', () => {
                applyFieldActive(fieldtobeActive, input);
            });
            input.addEventListener('blur', () => {
                applyFieldActive(fieldtobeActive, input);
            });
            input.addEventListener('click', () => {
                applyFieldActive(fieldtobeActive, input);
            });
            input.addEventListener('keyup', () => {
                applyFieldActive(fieldtobeActive, input);
            });
            break;
        }
    }
};

const initForm = function(form) {
    if (form.classList.contains('ready')){
        return;
    }
    const isGravityForm = form.querySelector('.gform_body') !== null;
    if (isGravityForm) {
        const fields = form.querySelectorAll('.gfield');
        for (const field of fields) {
            const inputs = field.querySelectorAll('input,select,button,textarea');
            initInputs(inputs, form, field, isGravityForm);
        }
    } else {
        const inputs = form.querySelectorAll('input,select,button,textarea');
        initInputs(inputs, form);
    }
    form.classList.add('ready');
};

const forms = document.querySelectorAll('form');
forms.forEach(initForm);

jQuery(document).on('gform_post_render', function(){
    const forms = document.querySelectorAll('form');
    forms.forEach(initForm);
});