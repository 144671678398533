/*! Agile Pixel https://agilepixel.io - 2022*/
/* eslint-disable */
import camelCase from "./camelCase";

const router = (() => {
	let routes = {};
	const setRoutes = (newroutes) => {
		routes = newroutes;
	};

	const fire = (route, function_, arguments_) => {
		if (typeof function_ == "undefined") {
			function_ = "init";
		}
		const fire =
			route !== "" &&
			routes[route] &&
			typeof routes[route][function_] === "function";
		const lazyimport =
			route !== "" && routes[route] && typeof routes[route] === "string";
		if (fire) {
			routes[route][function_](arguments_);
		} else if (lazyimport) {
			import(
				/* webpackChunkName: "[request]" */ `../routes/${routes[route]}`
			).then((module) => {
				if (typeof module[function_] === "function") {
					module[function_]();
				} else if (typeof module.default[function_] === "function") {
					module.default[function_]();
				}
			});
		}
	};

	const loadEvents = () => {
		// Fire common init JS
		fire("common");

		// Fire page-specific init JS, and then finalize JS
		for (const className of document.body.className
			.toLowerCase()
			.replace(/-/g, "_")
			.split(/\s+/)
			.map(camelCase)) {
			fire(className);
			fire(className, "finalize");
		}

		// Fire common finalize JS
		fire("common", "finalize");
	};
	return {
		setRoutes,
		fire,
		loadEvents,
	};
})();
export default router;
